<!-- 暂未使用 -->
<template>
  <div class="box-back">
    <div class="left">
      <div class="title">当前：{{ title }}</div>
      <slot name="default"></slot>
      <slot name="left"></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.box-back {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 10px;
  line-height: 32px;
  font-size: 14px;
  background-color: #ecf8ff;
  border-left: 4px solid #50bfff;
  border-radius: 4px;
  .title {
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
  }
}
</style>
