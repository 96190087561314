<!-- 暂未使用 -->
<template>
  <div class="b-detail-step">
    <el-steps finish-status="success" process-status="finish" :active="activeIndex" align-center>
      <el-step
        v-for="(item, index) in selfList"
        :key="index"
        :title="item.key"
        :description="item.desStr"
      />
    </el-steps>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: [Number, String],
      default: null
    },
    list: {
      type: Array,
      required: true
    }
  },
  computed: {
    activeIndex() {
      let result = -1
      this.list.forEach((item, index) => {
        if (item.val === Number(this.status)) result = index
      })
      return result
    },
    selfList() {
      let result = []
      this.list.forEach((itemSt) => {
        itemSt.desStr = ''
        let arr = []
        itemSt.des.forEach((itemNd) => {
          if (itemNd.key && itemNd.val) {
            arr.push(`${itemNd.key}: ${itemNd.val}`)
          }
        })
        itemSt.desStr = arr.join('；')
        result.push(itemSt)
      })
      return result
    }
  }
}
</script>

<style lang="less" scoped>
.b-detail-step {
  margin-bottom: 10px;
  background: @colorGrayF;
  height: 80px;
  /deep/ .el-steps {
    padding: 10px 5%;
    border-radius: 0;
    .el-step {
      .el-step__icon {
        width: 20px;
        height: 20px;
      }
      .el-step__title {
        margin-top: 2px;
        font-size: 14px;
        line-height: 20px;
      }
      .el-step__description {
        margin-top: 0;
        font-size: 11px;
      }
      &.is-center .el-step__description {
        padding: 0;
      }
    }
  }
}
</style>
