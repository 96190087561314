<!-- 暂未使用 -->
<template>
  <div class="b-detail-process">
    <div v-for="(item, index) in list" :key="index" class="process-item">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.b-detail-process {
  position: absolute;
  top: -36px;
  right: 0;
  display: flex;
  line-height: 20px;
  z-index: 99;
  .process-item {
    position: relative;
    margin-left: 10px;
    padding: 0 5px;
    font-size: 14px;
    color: @colorGrayLight;
    background: @colorWhite;
    border-radius: 2px;
    & ~ .process-item {
      &:after {
        position: absolute;
        content: '';
        top: 9px;
        left: -10px;
        font-size: 0;
        width: 10px;
        height: 2px;
        background: @colorWhite;
      }
    }
  }
}
</style>
