<template>
  <div class="header-filter">
    <div v-if="title">{{ title }}</div>
    <div class="header-input">
      <el-autocomplete
        v-if="type === 'autocomplete'"
        ref="refAutocomplete"
        style="width: 100%"
        size="mini"
        :debounce="500"
        v-model="keyword"
        :fetch-suggestions="autocompleteFilter"
        clearable
        @focus="autocompleteFocus"
        @input="autocompleteFocus"
        @clear="autocompleteClear"
        @keyup.enter.native="autocompleteEnter"
        @select="autocompleteSelect"
      />
      <e-date-picker
        v-if="type === 'daterange'"
        :value.sync="selfPickerDate"
        @change="datePickerChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'autocomplete'
    },
    title: {
      type: String,
      default: ''
    },
    field: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      keyword: '',
      filterData: {
        // is_delete: false
      },
      formData: {},
      list: []
    }
  },
  computed: {
    selfPickerDate: {
      get() {
        return this.value
      },
      set(val = ['', '']) {
        let result = this.$util.deepCopy(val)
        this.$emit('update:value', result)
      }
    }
  },
  methods: {
    // 自助筛选输入框-筛选关键字
    autocompleteFilter(keyword, callback) {
      let result = this.$util.deepCopy(this.list)
      if (keyword) {
        result = result.filter((item) => {
          let str1 = item.value.toLowerCase()
          let str2 = keyword.toLowerCase()
          return str1.includes(str2)
        })
      }
      callback(result) // 调用 callback 返回建议列表的数据
    },
    // 自助筛选输入框-聚焦
    autocompleteFocus() {
      if (!this.url) return
      let filterData = { ...this.filterData }
      filterData[this.field] = this.keyword
      this.$api({
        method: 'post',
        url: this.url,
        data: {
          heardAutoCompleteName: this.field,
          filterData
          // pickerDate: this.pickerDate,
          // formData: this.formData,
          // checkedGridClassIds: null
        }
      }).then((res) => {
        if (res.status === 200) {
          this.$nextTick(() => {
            this.list = []
            let resList = res.data.list || res.data.page.list
            let result = resList.map((item) => {
              return {
                value: String(item.value)
              }
            })
            this.list = result
            // this.$refs.refAutocomplete.focus()
          })
        }
      })
    },
    // 自助筛选输入框-清空
    autocompleteClear() {
      this.$refs.refAutocomplete.$children[0].blur()
      this.autocompleteSelect()
      setTimeout(() => {
        this.$refs.refAutocomplete.focus()
      }, 100)
    },
    // 自助筛选输入框-回车
    autocompleteEnter() {
      this.autocompleteSelect({ value: this.keyword })
    },
    // 自助筛选输入框-选中
    autocompleteSelect(item) {
      this.$emit('change', { field: this.field, value: item ? item.value : '' })
    },
    // 日期筛选-更新
    datePickerChange() {
      this.$emit('change', { field: this.field, value: this.$util.deepCopy(this.value) || [] })
    }
  }
}
</script>

<style lang="less" scoped>
.header-filter {
  color: @colorBlack;
  .header-input {
    margin-top: 2px;
    /deep/ .el-input__inner {
      height: 24px;
      color: @colorGrayDeep;
    }
    /deep/ .el-date-editor .el-range__icon {
      width: 18px;
      color: @colorBlue;
      line-height: 18px;
      margin-left: -5px;
    }
    /deep/ .el-input__icon {
      line-height: 24px;
    }
  }
}
</style>
