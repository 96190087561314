import Vue from 'vue'
import Vuex from 'vuex'
import Print from 'vue-print-nb'
import XEUtils from 'xe-utils'

// style
import 'element-ui/lib/theme-chalk/index.css'
import 'vxe-table/lib/style.css'
import 'vxe-table-plugin-element/dist/style.css'
import './assets/styles/common.less'

import App from './App.vue'
import router from './router'
import api from './api'
import store from './store'
import util from './utils/util'
import tool from './utils/tool'
import constant from './utils/constant'
import components from '@/components/index'

// 全局配置config
Vue.config.productionTip = false
Vue.config.devtools = true

// 全局引入插件
Vue.use(Vuex)
Vue.use(Print)

// element-ui
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN'
Vue.use(ElementUI, { locale })

// vex-table
import VXETable from 'vxe-table'
import VXETablePluginElement from 'vxe-table-plugin-element'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
Vue.use(VXETable)
VXETable.use(VXETablePluginElement)
VXETable.use(VXETablePluginExportXLSX)

// 全局引入方法 this.$xxx
Vue.prototype.$api = api // 接口
Vue.prototype.$XEUtils = XEUtils // 工具
Vue.prototype.$XModal = VXETable.modal // 模态框
Vue.prototype.$util = util // 方法-公用
Vue.prototype.$tool = tool // 方法-业务
Vue.prototype.$constant = constant // 常量

components(Vue)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
