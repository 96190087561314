<template>
  <div class="box-left">
    <div v-for="(itemSt, indexSt) in list" :key="indexSt" class="nav-item">
      <router-link class="nav-name" :to="itemSt.path">{{ itemSt.name }}</router-link>
      <div v-if="itemSt.children.length" class="nav-children">
        <div v-for="(itemNd, indexNd) in itemSt.children" :key="indexNd" class="nav-item">
          <router-link class="nav-name" :to="itemNd.path">{{ itemNd.name }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    list() {
      let result = []
      let arr = this.$util.deepCopy(this.$constant.navList)

      // 设置父元素的control集合
      arr.forEach((item) => {
        if (item.children && item.children.length) {
          let result = this.mergeChildControl(item.children)
          item.control = [...item.control, ...result]
        }
      })

      // 设置isShows数据
      arr.forEach((itemSt) => {
        if (itemSt.name !== '首页') itemSt.isShow = this.$tool.hasControl(itemSt.control)
        itemSt.children.forEach((itemNd) => {
          itemNd.isShow = this.$tool.hasControl(itemNd.control)
        })
      })

      // 提取isShow=true的数据
      arr.forEach((itemSt) => {
        if (itemSt.isShow) {
          let resultChild = itemSt.children.filter((itemNd) => {
            return itemNd.isShow
          })
          itemSt.children = resultChild
          result.push(itemSt)
        }
      })
      this.$store.commit('updateNavList', result)

      return result
    }
  },
  methods: {
    // 合并子集权限
    mergeChildControl(list) {
      let result = []
      list.forEach((item) => {
        result = [...result, ...item.control]
      })
      return result
    }
  }
}
</script>

<style lang="less" scoped>
.box-left {
  .nav-item {
    position: relative;
    .nav-name {
      display: block;
      padding: 0 10px;
      line-height: 40px;
      color: @colorWhite;
      cursor: pointer;
      text-decoration: none;
      &.router-link-active {
        color: @colorWhite;
        background: #396bf6;
      }
    }
    .nav-children {
      display: none;
      position: absolute;
      top: 0;
      left: @navWidth;
      width: @navWidth;
      white-space: nowrap;
      background: #2f333b;
      border-radius: 0 4px 4px 0;
      overflow: hidden;
      .nav-name {
        color: @colorWhite;
        &.router-link-exact-active {
          color: @colorWhite;
          background: #396bf6;
        }
      }
    }

    &:hover {
      > .nav-name {
        color: @colorWhite;
        background: #396bf6;
      }
      > .nav-children {
        display: block;
      }
    }
  }
}
</style>
