<template>
  <div class="lay-container">
    <div class="lay-head">
      <LayHead />
    </div>
    <div class="lay-main clearfix">
      <div class="lay-left">
        <LayLeft />
      </div>
      <div class="lay-right">
        <LayBreadcrumb />
        <div class="lay-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayHead from './lay-head'
import LayLeft from './lay-left'
import LayBreadcrumb from './lay-breadcrumb'
export default {
  components: {
    LayHead,
    LayLeft,
    LayBreadcrumb
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.lay-container {
  position: relative;
  height: 100%;
  .lay-head {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: @headHeight;
  }
  .lay-main {
    position: absolute;
    top: @headHeight;
    right: 0;
    bottom: 0;
    left: 0;
    .lay-left {
      width: @navWidth;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: #2f333b;
      z-index: 999;
    }
    .lay-right {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: @navWidth;
      .lay-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 42px 10px 0 10px;
        overflow-y: auto;
        .scroll-bar();
      }
    }
  }
}
</style>
