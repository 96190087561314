import axios from 'axios'
import ElementUI from 'element-ui'

axios.defaults.withCredentials = true // 允许携带cookie

// 在request拦截器中显示进度条Nprogress.start()
axios.interceptors.request.use((config) => {
  // 请求开始时显示进度条
  // NProgress.start();
  return config
})

// 返回状态判断
axios.interceptors.response.use((res) => {
  // res中完成进度条Nprogress.done()
  // NProgress.done();
  if (res.status === 200 && res.data.state == 'fail' && res.data.closeSystem) {
    window.location = 'http://www.baidu.com'
  } else if (
    res.status === 200 &&
    res.data.state == 'fail' &&
    res.data.showCommonStr
  ) {
    ElementUI.Message.error(res.data.msg)
  } else {
    return res
  }
  //closeSystem
})

export default axios
