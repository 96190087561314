import XEUtils from 'xe-utils'
import html2canvas from 'html2canvas'

// 控制台日志
let consoleLog = () => {
  // val1, val2, val3, val4, val5
  // console.log(val1, val2, val3, val4, val5)
}

// 获取日期
let getDate = () => {
  let now = new Date()
  let nowSeconds = now.getTime()
  let daySeconds = 3600 * 1000 * 24
  let result = {
    today: formatDate(now),
    sevenDay: formatDate(new Date(nowSeconds - daySeconds * 7)),
    monthDay: formatDate(new Date(nowSeconds - daySeconds * 30))
  }
  return result
}

// 日期格式
let formatDate = (date, hasTime = false, isEasy = false) => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')
  const second = date.getSeconds().toString().padStart(2, '0')

  let result = `${year}-${month}-${day}`
  if (hasTime) result += ` ${hour}:${minute}:${second}` // 时分秒
  if (isEasy) result = result.replace(/[\s:-]/g, '') // 去除空格、冒号、连字符
  return result
}

// 是否为手机端 暂未使用
let isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

// 是否为空
let isBlankVue = (obj) => {
  return (
    obj == null ||
    obj == '' ||
    obj == undefined ||
    obj == 'undefined' ||
    obj == 0 ||
    Number(obj) == 0
  )
}

// 是否为数字 暂未使用
let isNumberVue = (obj) => {
  obj = Number(obj)
  return typeof obj === 'number' && !isNaN(obj) && obj !== Infinity && Number(obj) !== 0
}

// 是否为数字，包含转换后类型为0，如null,''空字符串等 暂未使用
let isNumberZeroVue = (obj) => {
  obj = Number(obj)
  return typeof obj === 'number' && !isNaN(obj) && obj !== Infinity
}

// Vue浮点型加法 暂未使用
let numberAdd = (arg1, arg2) => {
  var r1, r2, m, n
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  n = r1 >= r2 ? r1 : r2
  return ((arg1 * m + arg2 * m) / m).toFixed(n)
}

// Vue浮点型减法 暂未使用
let numberSub = (arg1, arg2) => {
  var re1, re2, m, n
  try {
    re1 = arg1.toString().split('.')[1].length
  } catch (e) {
    re1 = 0
  }
  try {
    re2 = arg2.toString().split('.')[1].length
  } catch (e) {
    re2 = 0
  }
  m = Math.pow(10, Math.max(re1, re2))
  n = re1 >= re2 ? re1 : re2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

// Vue浮点型乘法 使用的话也很简单(乘法计算) {{ numberMul(0.0058,100) }} 暂未使用
let numberMul = (arg1, arg2) => {
  var m = 0
  var s1 = arg1.toString()
  var s2 = arg2.toString()

  m += s1.split('.')[1].length
  m += s2.split('.')[1].length

  return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m)
}

// Vue浮点型除法 除数，被除数， 保留的小数点后的位数 暂未使用
let numberDiv = (arg1, arg2, digit) => {
  var t1 = 0,
    t2 = 0,
    r1,
    r2
  t1 = arg1.toString().split('.')[1].length
  t2 = arg2.toString().split('.')[1].length
  r1 = Number(arg1.toString().replace('.', ''))
  r2 = Number(arg2.toString().replace('.', ''))
  // 获取小数点后的计算值
  var result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString()
  var result2 = result.split('.')[1]
  result2 = result2.substring(0, digit > result2.length ? result2.length : digit)

  return Number(result.split('.')[0] + '.' + result2)
}

/**
 * getPoint 小数点取整
 * @param {String} val 未处理的值
 * @param {String} len 保留小数点长度
 * @param {String} type 取整类型
 * @returns {Object} result 返回数据
 */
export function getPoint(val, type = 'down', len = 4) {
  let result = 0
  let num = 10 ** len
  let newVal = XEUtils.multiply(val, num)
  if (type === 'down') {
    result = XEUtils.divide(Math.floor(newVal), num)
  }
  if (type === 'up') {
    result = XEUtils.divide(Math.ceil(newVal), num)
  }

  return result
}

// 千分位 暂未使用
let formatMoney = (num) => {
  return XEUtils.commafy(num, { digits: 2 })
}

// 千分位 凭证 暂未使用
let formatMoneyVoucher = (s) => {
  let num = Number(s).toFixed(2) + ''
  if (!isBlankVue(s) && num.indexOf('.') == -1) {
    num = num + '00' // 如果没.说明是正数，自动多加00
  }
  if (!isBlankVue(s) && num.endsWith('.0')) {
    num = num + '0' // 如果没.说明是正数，自动多加00
  }
  if (!isBlankVue(s) && num.indexOf('.') > 0) {
    num = num.replace('.', '') // 有.则去掉
  }
  if (!isBlankVue(s) && num.indexOf(',') > 0) {
    num = num.replace(',', '') // 有.则去掉
  }
  if (num == 0) {
    num = ''
  }
  return num
}

// 获取屏幕高
let getViewHeight = () => {
  return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
}

// 小数 暂未使用
let howPoint = (obj) => {
  if (obj != null && XEUtils.isFloat(obj)) {
    // 如果是小数
    return obj.toString().split('.')[1].length
  } else {
    // 不是小数
    return 0
  }
}

/**
 * 防抖函数 暂未使用
 * @param func 用户传入的防抖函数
 * @param wait 等待的时间
 * @param immediate 是否立即执行
 */
let debounce = (func, wait = 50, immediate = false) => {
  // 缓存一个定时器id
  let timer = null
  let result
  const debounced = (...args) => {
    // 如果已经设定过定时器了就清空上一次的定时器
    if (timer) {
      clearTimeout(timer)
    }
    if (immediate) {
      const callNow = !timer
      // 等待wait的时间间隔后，timer为null的时候，函数才可以继续执行
      timer = setTimeout(() => {
        timer = null
      }, wait)
      // 未执行过，执行
      if (callNow) result = func.apply(this, args)
    } else {
      // 开始一个定时器，延迟执行用户传入的方法
      timer = setTimeout(() => {
        // 将实际的this和参数传入用户实际调用的函数
        func.apply(this, args)
      }, wait)
    }
    return result
  }
  debounced.cancel = () => {
    clearTimeout(timer)
    timer = null
  }
  // 这里返回的函数时每次用户实际调用的防抖函数
  return debounced
}

/**
 * 金额大写 暂未使用
 * @param amount 金额
 * @return 大写金额
 */
let amountUppercase = (amount) => {
  amount = XEUtils.round(amount, 2)
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖') // 汉字的数字
  var cnIntRadice = new Array('', '拾', '佰', '仟') // 基本单位
  var cnIntUnits = new Array('', '万', '亿', '兆') // 对应整数部分扩展单位
  var cnDecUnits = new Array('角', '分', '毫', '厘') // 对应小数部分单位
  var cnInteger = '整' // 整数金额时后面跟的字符
  var cnIntLast = '元' // 整型完以后的单位
  var maxNum = 999999999999999.9999 // 最大处理的数字
  var IntegerNum // 金额整数部分
  var DecimalNum // 金额小数部分
  var ChineseStr = '' // 输出的中文金额字符串
  var parts // 分离金额后用的数组，预定义
  var Symbol = '' // 正负值标记
  if (amount == '') {
    return ''
  }

  amount = parseFloat(amount)
  if (amount >= maxNum) {
    alert('超出最大处理数字')
    return ''
  }
  if (amount == 0) {
    ChineseStr = cnNums[0] + cnIntLast + cnInteger
    return ChineseStr
  }
  if (amount < 0) {
    amount = -amount
    Symbol = '负 '
  }
  amount = amount.toString() // 转换为字符串
  if (amount.indexOf('.') == -1) {
    IntegerNum = amount
    DecimalNum = ''
  } else {
    parts = amount.split('.')
    IntegerNum = parts[0]
    DecimalNum = parts[1].substr(0, 4)
  }
  if (parseInt(IntegerNum, 10) > 0) {
    // 获取整型部分转换
    var zeroCount = 0
    var IntLen = IntegerNum.length
    for (var i = 0; i < IntLen; i++) {
      var n = IntegerNum.substr(i, 1)
      var p = IntLen - i - 1
      var q = p / 4
      var m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          ChineseStr += cnNums[0]
        }
        zeroCount = 0 // 归零
        ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        ChineseStr += cnIntUnits[q]
      }
    }
    ChineseStr += cnIntLast
    // 整型部分处理完毕
  }
  if (DecimalNum != '') {
    // 小数部分
    var decLen = DecimalNum.length
    // eslint-disable-next-line no-redeclare
    for (var i = 0; i < decLen; i++) {
      // eslint-disable-next-line no-redeclare
      var n = DecimalNum.substr(i, 1)
      if (n != '0') {
        ChineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (ChineseStr == '') {
    ChineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (DecimalNum == '') {
    ChineseStr += cnInteger
  }
  ChineseStr = Symbol + ChineseStr

  return ChineseStr
}

// 输入聚焦
let inputFocus = (ref) => {
  if (isBlankVue(this.$refs[ref])) this.$refs[ref].focus()
}

// 输入失焦 暂未使用
let inputBlur = (ref) => {
  if (isBlankVue(this.$refs[ref])) this.$refs[ref].blur()
}

/**
 * deepCopy 深拷贝
 * @param {Object, Array, String, Number, Boolean} val 拷贝数据
 * @returns {Object, Array, String, Number, Boolean} result 返回数据
 */
let deepCopy = (val) => {
  if (typeof val !== 'object' || val === null) {
    return val
  }

  let result
  if (Array.isArray(val)) {
    result = []
    for (let i = 0; i < val.length; i++) {
      result[i] = deepCopy(val[i])
    }
  } else {
    result = {}
    for (let key in val) {
      if (Object.prototype.hasOwnProperty.call(val, key)) {
        result[key] = deepCopy(val[key])
      }
    }
  }

  return result
}

/**
 * deepMerge 深合并
 * @param {Object, Array, String, Number, Boolean} obj1 拷贝数据
 * @param {Object, Array, String, Number, Boolean} obj2 拷贝数据
 * @returns {Object, Array, String, Number, Boolean} result 返回合并数据
 */
let deepMerge = (obj1, obj2) => {
  obj1 = deepCopy(obj1)
  obj2 = deepCopy(obj2)
  let key
  for (key in obj2) {
    // 如果target(也就是obj1[key])存在，且是对象的话再去调用deepMerge，否则就是obj1[key]里面没这个对象，需要与obj2[key]合并
    // 如果obj2[key]没有值或者值不是对象，此时直接替换obj1[key]
    obj1[key] =
      obj1[key] &&
      obj1[key].toString() === '[object Object]' &&
      obj2[key] &&
      obj2[key].toString() === '[object Object]'
        ? deepMerge(obj1[key], obj2[key])
        : (obj1[key] = obj2[key])
  }
  return obj1
}

/**
 * windowOpenImg 窗口打开图片
 * @params {url} 图片地址
 */
let windowOpenImg = (url) => {
  if (!url) return
  const image_window = window.open('', '_blank')
  image_window.document.write(`
      <html>
        <head>
        </head>
        <body>
          <img src="${url}">
        </body>
      </html>
`)
}

/**
 * canvas生成图片
 * https://github.com/niklasvh/html2canvas
 * https://html2canvas.hertzen.com/configuration
 */
let canvasToImg = (element, body, name = '生成图片') => {
  html2canvas(element, {
    useCORS: true,
    proxy: 'https://www.gcvip168.com'
  }).then((canvas) => {
    let img_base64 = canvas.toDataURL('image/png')
    // windowOpenImg(img_base64)
    let now = new Date()
    let img_name = `${name}-${formatDate(now, true, true)}`
    let a = document.createElement('a')

    a.href = img_base64
    a.download = img_name
    body.appendChild(a)
    a.click()
    setTimeout(() => {
      body.removeChild(a)
    }, 1000)
  })
}

const util = {
  consoleLog,
  getDate,
  formatDate,
  isMobile,
  isBlankVue,
  isNumberVue,
  isNumberZeroVue,
  numberAdd,
  numberSub,
  numberMul,
  numberDiv,
  getPoint,
  formatMoney,
  formatMoneyVoucher,
  getViewHeight,
  howPoint,
  debounce,
  amountUppercase,
  inputFocus,
  inputBlur,
  deepCopy,
  deepMerge,
  windowOpenImg,
  canvasToImg
}

export default util
